import React from "react";
import ProposalImg from "../images/proposal.jpg"
import PlansetImg from "../images/eng_service_new.png"
import RoofImg from "../images/asbuilt.png"
import StampImg from "../images/pe_stamp_new.png"
import AppImg from "../images/application.jpeg"

const ServiceList = () => {
  const cards = [
    {
        imageSrc: ProposalImg,
        title: "Sales Proposals",
        description:
          "Have a prelim solar proposal for your next sales appointment and sell with confidence. The proposal depicts the house with the solar panels and equipment of choice placed on roof planes with best irradiance. Proposals are created using Nearmap aerial imaging and include a detailed report."
    },

    {
        imageSrc: PlansetImg,
        title: "Design Plansets",
        description:
          "AHJ & utility specific permit package CAD document for permitting and interconnection approval. The PV design is a detailed plan set that includes the cover sheet, site and stringing plan, racking detail, line diagrams with calculations, code compliant labels and equipment datasheets. Each PV design will also carry a complete breakdown of the Bill of Materials."
    },

    {
        imageSrc: RoofImg,
        title: "Roof Evaluation",
        description:
          "Pair your PV Design with AHJ mandated structural letter. Our services also include Flood elevation certificates, Structure reinforcement letters, post-install letters and more. "
    },

    {
        imageSrc: StampImg,
        title: "Electrical Stamps",
        description:
          "We facilitate obtaining PE structural and electrical stamps for all projects across all 50 states where the AHJ has a stamping requirement."
    },

    {
        imageSrc: AppImg,
        title: "Permit and Interconnection Applications",
        description:
          "Get your permits approved the first time with access to our in-depth Jurisdiction and Utility database categorized by state with special requirements from each city/county and utility.",
        url: "https://timerse.com"
    }
  ];

  return (
    <div>
      <div className="max-w-screen-xl mx-auto py-20 lg:py-24">
        <div className="flex flex-col items-center">
          <div className="text-4xl sm:text-5xl font-bold text-orange-400 tracking-wide text-center">
            Popular Services
            </div>
        </div>

        <div className="mt-16">
          {cards.map((card, i) => (
            <div key={i} reversed={i % 2 === 1} 
                className="mt-24 md:flex justify-center items-center flex-row odd:flex-row-reverse">
              <img className="rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center 
                mx-4 sm:mx-8 md:mx-4 lg:mx-8" src={card.imageSrc} alt=""/>
              <div className="mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8">
                <div className="text-3xl font-bold text-orange-400">{card.title}</div>
                <p className="mt-2 text-md text-gray-200 leading-loose">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceList
