import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../src/pages/HomePage";
import Services from "../src/pages/Services";
import About from "../src/pages/About";
import Contact from "../src/pages/Contact";
import NotFound from "../src/pages/NotFound";
import ThankYou from "./pages/ThankYou";
import ScrollToTop from "./ScrollToTop";


function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
