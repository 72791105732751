import React from 'react'

const AboutHero = () => {
  return (
    <div className="bg-center bg-about-hero h-screen min-h-144">
        <div className='z-20 px-6 sm:px-8 mx-auto h-full flex flex-col'>
            <div className='px-4 flex flex-1 flex-col justify-center items-center'>
                <h1 className='text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-bold 
                    text-white leading-snug -mt-24 sm:mt-0'>
                    Your Dedicated Solar Design and Engineering Partner. 
                </h1>
            </div>
        </div>
      
    </div>
  )
}

export default AboutHero
