import React from "react";
import CompanyLogo from "../images/result.svg";
import LinkedinIcon from "../images/linkedin.svg";
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div className="-mx-8 -mb-8">
      <div className="max-w-screen-xl mx-auto py-20 lg:py-24">
        <div className="flex items-center justify-center flex-col px-8">
          <div className="flex items-center justify-center md:justify-start">
            <img className="w-[200px] h-[50px] pt-3" src={CompanyLogo} alt="Logo" />
            {/* <h5 className="ml-2 text-2xl text-orange-400 tracking-wider">Sungalactic</h5> */}
          </div>
          <div className="mt-8 font-medium flex flex-wrap justify-center items-center text-gray-200
            flex-col sm:flex-row">
            <Link to="/" className="border-b-2 border-transparent hover:text-orange-400 
                pb-1 transition duration-300 mt-2 mx-4">
                Home
            </Link>
            <Link to="/about-us" className="border-b-2 border-transparent hover:text-orange-400 
                pb-1 transition duration-300 mt-2 mx-4">
                About
            </Link>
            <Link to="/services" className="border-b-2 border-transparent hover:text-orange-400  
                pb-1 transition duration-300 mt-2 mx-4">
                Services
            </Link>
            <Link to="/contact" className="border-b-2 border-transparent hover:text-orange-400  
                pb-1 transition duration-300 mt-2 mx-4">
                Contact
            </Link>
          </div>
          <div className="mt-10">
            <a href="https://in.linkedin.com/company/sungalactic-renewable-energy" className="cursor-pointer inline-block transition duration-300 mx-4">
                <img className=" w-10 h-10" src={LinkedinIcon} alt="LinkedIn"></img>
            </a>
          </div>
          <p className="text-center mt-10 font-medium tracking-wide text-sm text-gray-400">
            &copy; Copyright 2023, Sungalactic Renewables LLC. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
