import React from "react";
import { Link } from "react-router-dom";

export default ({
  text = "Guaranteed to beat your existing costs. Contact us today and get your first 5 plansets on us.",
  secondaryLinkText = "Get Started",
}) => {
  return (
    <div className="mb-20 lg:mb-24">
        <div className="max-w-screen-3xl mx-auto py-20 lg:py-24">
            <div className="py-20 lg:py-20 bg-black">
                <div className="px-8 max-w-screen-lg mx-auto flex items-center z-10 flex-col 
                    lg:flex-row text-center lg:text-left">
                    <div className="lg:w-2/3 max-w-2xl">
                    <h5 className="text-gray-100 text-2xl sm:text-3xl font-bold">{text}</h5>
                    </div>
                    <div className="lg:w-1/3 max-w-lg flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row">
                      <Link to="/contact">
                        <div className="w-full sm:w-auto text-sm md:text-md lg:text-lg sm:text-base px-6 py-3 sm:px-8 
                        sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-lg font-bold border border-transparent 
                        tracking-wide transition duration-300 focus:outline-none focus:shadow-outline
                        text-black bg-white hover:text-orange-400 cursor-pointer font-ptsansbold">
                          {secondaryLinkText}
                        </div>
                      </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};
