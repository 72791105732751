import React from "react";

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  formAction = "https://formsubmit.co/4c6724f827b48cc4815952641f3cd840",
  formMethod = "POST",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24">
        <div className="w-full max-w-md mx-auto md:max-w-none md:mx-0 md:w-6/12 
            flex-shrink-0 md:h-auto">
          <div class="mt-16 mb-10 w-full md:w-full lg:w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 
            md:px-3 lg:px-6">
            <h2 class="mb-6 text-3xl text-orange-400 font-bold">Contact us</h2>
            <p class="mb-6 text-gray-200">
              Get in touch with us by filling out the form. <br />
              Expect a reply within 24 hours. 
            </p>
            <p class="mt-24 text-gray-200 text-lg">
              Sungalactic Renewables LLC
            </p>
            <p class="mb-2 text-gray-200 text-sm">
              Delaware, United States
            </p>
            <p class="mb-2 text-gray-200 text-lg">
              +1 512-265-5249
            </p>
            <p class="mb-2 text-gray-200 text-lg">
              info@sungalactic.com
            </p>
          </div>
        </div>
        <div className="md:w-6/12 md:mt-0 md:ml-12 lg:ml-16 md:order-last">
          <div className="lg:py-8 text-center md:text-left">
            <form className="mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0" 
                action={formAction} method={formMethod}>
              <input className="mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 
                hocus:border-primary-500 px-2" type="text" name="name" placeholder="Name" required/>
              <input className="mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 
                hocus:border-primary-500 px-2" type="email" name="email" placeholder="Email Address" required/>
              <input className="mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 
                hocus:border-primary-500 px-2" type="number" name="phone" placeholder="Phone Number" required/>
              <textarea className="mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 
                hocus:border-primary-500 h-36 px-2" type="text" name="message" placeholder="Message" required/>
              <input type="hidden" name="_next" value="https://sungalactic.com/thankyou" />
              <button className="mt-6 px-8 py-4 font-bold rounded bg-chromegray text-gray-100 hocus:bg-primary-700 
                hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300" 
                type="submit">
                    {submitButtonText}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
