import React from "react";
import { Link } from "react-router-dom";

export default () => {
  const services = [
    {
      category: "Design",
      description: "Why worry in the world of Sungalactic? Residential, Commercial and Utility scale Sales Proposals and Design Plansets delivered \
        lightning fast without any hassle. Stay in direct contact with your account manager the entire time.",
      url: "https://sungalactic.com"
    },
    {
      category: "Engineering",
      description: "Want to ensure that your projects are in compliance with all codes and regulations? Get an Engineer's approval for structural \
      letters and PE Stamps across all 50 states within 24 Hours.",
      url: "https://sungalactic.com"
    },
    {
      category: "Permitting & Interconnection",
      description: "Unsure about navigating Jurisdictions? We got you covered. Get your plansets approved with a first time right confidence when you opt for Sungalactic.",
      url: "https://sungalactic.com"
    }
  ];
  return (
    <section className="mt-16">
      <div className="max-w-screen-xl mx-auto py-20 lg:py-24">
        <div className="flex flex-col items-center">
          <div className="mt-4 font-bold text-orange-400 text-2xl md:text-3xl lg:text-4xl
            text-center max-w-sm">Services</div>
          {/* <div className="mt-4 font-medium text-gray-200 text-xl text-center max-w-sm">
            List of comprehensive services.
          </div> */}
        </div>
        <div className="flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap">
          {services.map((service, index) => (
            <div className="mt-24 lg:w-1/3" key={index}>
              <div className="lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs box-border rounded-xl shadow-lg 
                shadow-orange-400 w-80 h-80 bg-black">
                <div className="mt-4 text-secondary-100 text-gray-200 font-bold text-center text-2xl pt-6">
                    {service.category}
                </div>
                <h4 className="mt-6 leading-relaxed text-gray-200 text-md px-2">{service.description}</h4>
                <Link to="/services">
                  <div className="inline-block mt-2 text-lg text-orange-400 font-bold cursor-pointer 
                    transition duration-300 border-b-2 border-transparent hover:border-primary-500 px-2">
                    Learn More
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
