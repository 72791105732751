import React from "react";

import TurnaroundImage from "../images/turnaround2.svg";
import ClockImage from "../images/clock2.svg";
import SimpleImage from "../images/simple2.svg";
import UnlimitedImage from "../images/unlimited2.svg";
import ReliableImage from "../images/reliable2.svg";
import DedicatedImage from "../images/account2.svg";

export default ({ cards = null }) => {

  const Cards = [
    {
        imageSrc: TurnaroundImage,
        title: "12 Hour Turnaround",
        description: "12 Hrs or less turnaround for designs and 24 Hrs or less \
        turnaround for engineering stamps and structural letters."
    },
    { 
      imageSrc: UnlimitedImage, 
      title: "Unlimited Revisions",
      description: "Guaranteed customer satisfaction with design plansets offering unlimited revisions FREE of charge."
    },
    { 
      imageSrc: DedicatedImage, 
      title: "Dedicated Account Manager",
      description: "Stay in touch with your own account manager to get complete clarity regarding projects"
    },
    { 
        imageSrc: ClockImage, 
        title: "24/7 Support",
        description: "Round the clock assistance for clients to cater to all their needs and support all timezones."
    },
    { 
        imageSrc: SimpleImage, 
        title: "Simple",
        description: "Simple and Easy to use with our project management tools to track projects."
    },
    { 
        imageSrc: ReliableImage, 
        title: "Reliable",
        description: "Trusted design and engineering partner of 30+ clients across the US and Canada."
    }
  ];

  cards = Cards;

  return (
    <div className="mt-16">
      <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap 
        md:justify-center max-w-screen-lg mx-auto py-20 md:py-24">
        <div className="w-full text-center mt-4 text-2xl md:text-3xl lg:text-4xl font-bold leading-relaxed 
          max-w-xl text-orange-400">
            How We Stand Out
        </div>
        <div className="mt-10 w-full"></div>
        {cards.map((card, i) => (
          <div className="md:w-1/2 lg:w-1/3 max-w-sm" key={i}>
            <div className="flex flex-col sm:flex-row items-center sm:items-start text-center 
              sm:text-left h-full mx-4 px-2 py-8">
              <span className="border border-orange-400 text-center rounded-full p-5 flex-shrink-0">
                <img className="w-6 h-6" src={card.imageSrc} alt="" />
              </span>
              <span className="sm:ml-4 mt-4 sm:mt-2">
                <span className="mt-4 tracking-wide font-bold text-2xl leading-none text-gray-200">
                  {card.title}
                </span>
                <p className="mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose text-gray-200">
                  {card.description}
                </p>
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48" />
    </div>
  );
};
