import React from 'react'
import Navbar from "../components/Navbar"
import Footer from '../components/Footer'
import AboutHero from '../components/AboutHero'
import AboutCompany from '../components/AboutCompany'

const About = () => {
  return (
    <div className='mt-16'>
      <Navbar />
      <AboutHero />
      <AboutCompany />
      <Footer />
    </div>
  )
}

export default About