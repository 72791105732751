import React from 'react'

const ThankYou = () => {
  return (
    <h1 className="max-w-xl lg:max-w-xl text-[2.0rem] leading-none lg:leading-normal text-gray-200 font-extrabold 
        font-sans text-center lg-text-3xl lg:leading-tight mb-5 py-20">
        Thank you for contacting us. We will get back to you at the earliest.
    </h1>
  )
}

export default ThankYou