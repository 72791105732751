import React from 'react'
import HeroButton from './HeroButton';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className="py-10 bg-home-hero bg-no-repeat bg-opacity-700 h-screen">
        <div className="container flex flex-wrap items-center justify-center mx-auto mt-10 md:px-12 md:flex-row">
            <div className="mb-14 lg:mb-0">
                <h1 className="max-w-xl lg:max-w-xl text-[2.9rem] leading-none lg:leading-normal text-gray-200 font-bold
                    text-center lg-text-5xl xl:text-6xl lg:leading-tight mb-5 lg:py-12">
                    One stop shop to all your Design and Engineering needs.
                </h1>
                <p className="max-w-xl text-2xl text-center text-orange-400 lg:max-w-xl">
                    Engineering better Tomorrows.
                </p>
                <div className="flex justify-center mt-14">
                    <Link to="/services">
                        <HeroButton className="bg-black text-white">Learn More</HeroButton>
                    </Link>
                </div>
                <div className="grid grid-cols-3 gap-0 items-center justify-center text-center py-10 md:mt-8 
                    lg:mt-12 text-black lg:text-gray-200">
                    <div>
                        <h2 className="text-[1.2rem] md:text-[1.5rem] lg:text-[1.8rem] text-orange-400 font-bold">
                            50,000+
                        </h2>
                        <span className="text-[1.0rem] md:text-[1.2rem] lg:text-[1.4rem]">Projects Completed</span>
                    </div>
                    <div>
                        <h2 className="text-[1.2rem] md:text-[1.5rem] lg:text-[1.8rem] text-orange-400 font-bold">
                            20+
                        </h2>
                        <span className="text-[1.0rem] md:text-[1.2rem] lg:text-[1.4rem]">Clients</span>
                    </div>
                    <div>
                        <h2 className="text-[1.2rem] md:text-[1.5rem] lg:text-[1.8rem] text-orange-400 font-bold">
                            150+
                        </h2>
                        <span className="text-[1.0rem] md:text-[1.2rem] lg:text-[1.4rem]">MegaWatts Installed</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero