import React from 'react'
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ServiceHero from '../components/ServiceHero'
import ServiceList from '../components/ServiceList'

const Services = () => {
  return (
    <div className="mt-16">
      <Navbar />
      <ServiceHero />
      <ServiceList />
      <Footer />
    </div>
  )
}

export default Services