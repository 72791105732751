import React from 'react'
import Navbar from "../components/Navbar"
import Hero from '../components/Hero'
import Features from '../components/Features'
import Footer from '../components/Footer'
import ServiceCard from '../components/ServiceCard'
import GetStarted from '../components/GetStarted'

const HomePage = () => {
  return (
    <div className="mt-16">
      <Navbar />
      <Hero />
      <ServiceCard />
      <Features />
      <GetStarted />
      <Footer />
    </div>
  )
}

export default HomePage
