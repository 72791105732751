import React from 'react'

const NotFound = () => {
  return (
    <div className="bg-white w-100% h-screen">
        Page not found
    </div>
  )
}

export default NotFound