import React from 'react'
import { Link } from 'react-router-dom';

const NavButton = (props) => {

  return (
    <Link to="/contact">
      <button className='bg-white text-black font-ptsansbold text-lg py-2 px-4 rounded md:ml-8 hover:text-orange-400 
      duration-300 ease-in-out'>
        {props.children}
      </button>
    </Link>
  )
}

export default NavButton