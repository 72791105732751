import React from 'react'

const AboutCompany = () => {
  return (
    <div className="mt-10 mb-20 lg:mb-24">
        <div className="max-w-screen-3xl mx-auto py-10 lg:py-24 text-gray-200">
            <div className="flex flex-col px-8">
              <div className="mt-4 font-bold text-orange-400 text-2xl md:text-3xl lg:text-4xl
                text-center">Who We Are</div>
              <div className="mt-10 font-medium text-gray-200 font-ptsansitalic text-xl text-center">
                “To provide seamless Design &amp; Engineering assistance for swift project installation through
                uncompromising customer service irrespective of service offered.”
              </div>
              <div className="mt-10 text-gray-200 text-xl sm:text-center lg:text-left">
                Sungalactic is a Global Engineering Solutions provider for Renewable Energy and Energy
                storage infrastructure. We bring a comprehensive approach to problem solving through our team
                of experienced renewable energy engineers and the use of latest technologies. With over 40 years
                of cumulative experience, we leverage our profound knowledge of the National Electric Code,
                AHJ and Utility requirements to engineer ideas for a guaranteed approval. 
                <br />
                <br />
                We work behind the curtain to design and develop a complete permit package document
                including the CAD design with stamps and engineering letter. Each CAD design is customizable
                to each client. Unlike our competition, we are not limited to a linear presentation.
              </div>
            </div>
        </div>
    </div>
  )
}

export default AboutCompany
