import React, { useState } from 'react'
import { Link as NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NavButton from './NavButton';
import Menu from '../images/menu.svg';
import Close from '../images/close.svg';
import CompanyLogo from '../images/result.svg'

const Navbar = () => {
  let Links =[
    {name:"Home",link:"/"},
    {name:"About",link:"/about-us"},
    {name:"Services",link:"/services"},
  ];
  let [open,setOpen]=useState(false);
  
  return (
    <header className='shadow-md w-full fixed top-0 left-0'>
      <div className='md:flex items-center justify-between bg-black py-4 md:px-24 px-7'>
        <div className='text-3xl cursor-pointer flex items-center text-orange-400'>
          <span className='mr-1 pt-2'>
            <Link to="/">
            <img src={CompanyLogo} name="logo-ionic" className="w-[200px] h-[50px]" alt='logo'></img>
            </Link>
          </span>
          
        </div>
      
        <div onClick={()=>setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
          <img src={open ? Close:Menu} className="w-[28px] h-[28px] object-contain"></img>
        </div>

        <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-black md:z-auto z-[-1]
          left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-300 ease-in-out
          ${open ? 'top-20 ':'top-[-490px]'}`}>
          {
            Links.map((link)=>(
              <li key={link.name} className='md:ml-8 text-lg md:my-0 my-7 md:px-2'>
                <NavLink to={link.link} className='font-ptsans text-gray-200 hover:text-orange-400 duration-500'>
                  {link.name}
                </NavLink>
              </li>
            ))
          }
          <NavButton>
            Contact
          </NavButton>
        </ul>
      </div>
    </header>
  )
}

export default Navbar