import React from 'react'

const HeroButton = (props) => {
  return (
    <button className='bg-black text-white font-ptsansbold text-lg py-2 px-4 rounded md:ml-8 hover:text-orange-400 
    duration-300 ease-in-out'>
      {props.children}
    </button>
  )
}

export default HeroButton